import React, { useEffect, useRef, useState } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import "./Skill.css";

function Skill({
    name = "skillname",
    icon = "",
    description = "description",
    gradientColor = "rgba(0, 0, 0, 0.05)",
}) {
    const ref = useRef(null);
    const isInView = useInView(ref);
    const mainControls = useAnimation();
    const [transitionStyle, setTransitionStyle] = useState({});
    const [fadeOpacity, setFadeOpacity] = useState(0);

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
            setFadeOpacity(1);
        } else {
            mainControls.start("hidden");
            setFadeOpacity(0);
        }
    }, [isInView, mainControls]);

    const handleAnimationComplete = () => {
        setTransitionStyle({ transition: "all 0.1s linear" });
    };

    let iconSrc = "";
    try {
        iconSrc = require(`../assets/icons/${icon}`);
    } catch (error) {
        console.error(`Image not found for skill: ${name}`);
    }

    const skillVariants = {
        hidden: { opacity: 0, y: 75, scale: 1.1 },
        visible: { opacity: 1, y: 0, scale: 1 },
    };

    const gradientVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    };

    return (
        <motion.div
            ref={ref}
            className="skill"
            style={{ position: "relative", overflow: "hidden", ...transitionStyle }}
            variants={skillVariants}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.2, delay: 0 }}
            onAnimationComplete={handleAnimationComplete}
        >
            {iconSrc && <img src={iconSrc} alt={`${name} Icon`} width={40} />}
            <h1>{name}</h1>
            <p dangerouslySetInnerHTML={{ __html: description }} />

            <motion.div
                className="skill-gradient"
                variants={gradientVariants}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 1, ease: "easeInOut" }}
                style={{
                    background: `linear-gradient(to top, ${gradientColor}, transparent)`,
                    ...transitionStyle,
                }}
            ></motion.div>
        </motion.div>
    );
}

export default Skill;
