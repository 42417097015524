import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

import "./Social.css";

function Social({ icon, link, animateDelay = 0, text, additionalClasses = "" }) {
    const ref = useRef(null);
    const isInView = useInView(ref);

    const controls = useAnimation();

    useEffect(() => {
        if (isInView) {
            controls.start("visible");
        } else {
            controls.start("hidden");
        }
    }, [isInView, controls]);

    const variants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.div
            ref={ref}
            variants={variants}
            initial="hidden"
            animate={controls}
            transition={{ duration: 0.5, delay: animateDelay }}
            className="social-container"
        >
            <a
                className={`social ${additionalClasses}`}
                target="_blank"
                href={link}
                rel="noopener noreferrer"
            >
                <img src={icon} alt="Social Icon" />
                <p dangerouslySetInnerHTML={{ __html: text }} />
            </a>
        </motion.div>
    );
}

export default Social;
