import React, { useEffect } from "react";

import "./Projects.css";
import Box from "./components/Box";

// Images
import LibrusStatisticsImage from "./assets/images/LibrusStatistics/LibrusStatistics.png";
import LibrusStatisticsBackgroundImage from "./assets/images/LibrusStatistics/LibrusStatisticsBackground.png";

import VeamlyImage from "./assets/images/Veamly/Veamly.png";
import VeamlyBackgroundImage from "./assets/images/Veamly/VeamlyBackground.png";

import YouTubeMusicManager from "./assets/images/YouTubeMusicManager/YouTubeMusicManager.png";
import YouTubeMusicManagerBackground from "./assets/images/YouTubeMusicManager/YouTubeMusicManagerBackground.png";

import SharkBreakImage from "./assets/images/SharkBreak/SharkBreak.png";
import SharkBreakBackgroundImage from "./assets/images/SharkBreak/SharkBreakBackground.png";

import FilareImage from "./assets/images/Filare/Filare.png";
import FilareBackgroundImage from "./assets/images/Filare/FilareBackground.png";

import HeartOfTheSettlementImage from "./assets/images/HeartOfTheSettlement/HeartOfTheSettlement.png";
import HeartOfTheSettlementBackgroundImage from "./assets/images/HeartOfTheSettlement/HeartOfTheSettlementBackground.png";

function Projects() {
    useEffect(() => { }, []);

    return (
        <section id="projects">
            <h1 className="brackets">Projects</h1>
            <div>
                <div id="projects-grid">
                    <Box
                        title={"Librus Statistics [Polish Language]"}
                        description={`
                            <b>Librus Statistics</b> is a web application developed using React. App is dedicated to polish residents that allows you to access information from:
                            <a style="color: #608fbf; font-weight: bold;" href="https://portal.librus.pl/rodzina" target="_blank" rel="noopener noreferrer">portal.librus.pl/rodzina</a>.<br\><br\>
                            Includes information about your own account in terms of subjects, attendance, and grades of the current year.
                        `}
                        link={"https://ls.xsiadron.com"}
                        image={LibrusStatisticsImage}
                        backgroundImage={LibrusStatisticsBackgroundImage}
                        author={"xsiadron"}
                        date={"Released in 06.2023"}
                    />

                    <Box
                        title={"Veamly - Assign your meanings"}
                        description={`
                            <b>Veamly</b> is native android application allowing to save your own meanings of words or their translations.<br /><br />
                            <span style="color: #608fbf; font-weight: bold;">App facilitates memorization and learning</span> & can be used as well as a kind of <b>notebook</b>.<br /><br />
                            Once installed, you will find many different functionalities including:
                            <ul>
                              <li><b>Self-created dictionaries</b></li>
                              <li><b>Advanced memorisation tests</b></li>
                              <li><b>Flash cards to teach new vocabulary</b></li>
                            </ul>
                            Enjoy learning with <b>Veamly</b>.
                          `}

                        link={
                            "https://play.google.com/store/apps/details?id=com.xsiadron.veamly"
                        }
                        image={VeamlyImage}
                        backgroundImage={VeamlyBackgroundImage}
                        author={"xsiadron"}
                        date={"Released in 02.2023"}
                    />

                    <Box
                        title={"Youtube Music Manager"}
                        description={`
                            <strong>YouTube Music Manager</strong> is a must-have extension!</strong><br />
                            Add precise volume <b>percentages</b> to the slider and enjoy improved <b>responsiveness</b>.<br /><br />
                            <strong>Build-in Auto-Pause Manager</strong> pauses music when you play videos
                            and <i>resumes it automatically</i> when they stop, 
                            making <b>multitasking</b> seamless and convenient.
                          `}
                        link={
                            "https://chromewebstore.google.com/detail/youtube-music-volume-slid/igbflikmkacfojkpipddglnmaaoanlaj"
                        }
                        image={YouTubeMusicManager}
                        backgroundImage={YouTubeMusicManagerBackground}
                        author={"xsiadron"}
                        date={"Released in 07.2024"}
                    />
                </div>
            </div>
        </section>
    );
}

export default Projects;
